import { CalloutCard } from '../../types/api';
import { Attributes } from 'html-react-parser/lib/attributes-to-props';
import styles from '../../styles/components/blocks/callout-card-row.module.scss';

interface CalloutCardRow {
  cards?: Array<CalloutCard>;
  attributes?: Attributes;
}

export default function CalloutCardRow({ cards, attributes }: CalloutCardRow): JSX.Element {
  return (
    <div className="container">
      {cards?.length ? (
        <div className={styles.cardsGrid}>
          {cards?.map((card, i) => {
            return (
              <div id={attributes?.anchor} key={i} className={styles.cardWrapper}>
                {card?.icon ? (
                  <div className={styles.iconWrapper}>
                    <img src={card?.icon?.sourceUrl} alt={card?.icon?.altText} />
                  </div>
                ) : null}
                <div className={styles.titleTextWrapper}>
                  {card?.title ? <h2>{card.title}</h2> : null}
                  {card?.text ? <div className={styles.textWrapper} dangerouslySetInnerHTML={{ __html: card.text }}></div> : null}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
